export default class AutoAnswerType {
  options
  text
  interactive
  noResponse

  constructor () {
    this.options = [
      { text: 'Sin respuesta', value: 'noResponse' },
      { text: 'Solo texto', value: 'text' },
      { text: 'Mensajes interactivos', value: 'interactive' },
    ]
    this.text = 'text'
    this.interactive = 'interactive'
    this.noResponse = 'noResponse'
  }
}
