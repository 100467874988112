import moment from 'moment'
import VueTagsInput from '@johmun/vue-tags-input'
import EventBus from '@/util/EventBus'
import State from '@/util/State'
import Campaign from '@/models/Campaign'
import { ChannelType } from '@/models/ChannelType'
import { SendingStatuses } from '@/models/SendingStatuses'
import { CampaignType } from '@/models/CampaignType'
import { ChannelWhatsapp } from '@/models/ChannelWhatsapp'
import ComponentStatusStore from '@/models/ComponentStatusStore'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import { PhonesWithPrefix as PhonesWithPrefixClass } from '@/models/PhonesWithPrefix'
import { CurrentWhatsapp } from '@/classes/CurrentWhatsapp'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import { WhatsappComponentsData } from '@/classes/componentsData/WhatsappComponentsData'
import { WhatsappPreview } from '@/classes/preview/WhatsappPreview'
import WhatsappTemplateService from '@/services/whatsapp-template.service.js'
import groupService from '@/services/group.service'
import CampaignService from '@/services/campaign.service'
import LandingTemplateService from '@/services/landing-template.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import SendLater from '@/components/SendLater/SendLater.vue'
import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import ContactSource from '@/components/ContactSource/ContactSource.vue'
import PricesByCountry from '@/components/Modals/PricesByCountry/PricesByCountry.vue'
import FirstSection from '@/components/FirstSection/FirstSection.vue'
import MeetOptions from '@/components/MeetOptions/MeetOptions.vue'
import BirthdayOptions from '@/components/BirthdayOptions/BirthdayOptions.vue'
import TestModal from '@/components/Modals/TestModal/TestModal.vue'
import BalanceAlert from '@/components/Modals/BalanceAlert/BalanceAlert.vue'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import ImportFile from '@/components/ContactSource/ImportFile/ImportFile.vue'
import ImportManual from '@/components/ContactSource/ImportManual/ImportManual.vue'
import ImportGroups from '@/components/ContactSource/ImportGroups/ImportGroups.vue'
import ChangeSourceDialog from '@/components/ContactSource/ChangeSourceDialog/ChangeSourceDialog.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingEditor from '@/components/Template/LandingEditor/LandingEditor.vue'
import CampaingHeaderTitle from '../CampaingHeader/CampaingHeaderTitle/CampaingHeaderTitle.vue'
import CampaignPreview from '../CampaignPreview/CampaignPreview.vue'
import SecondSection from './SecondSection/SecondSection.vue'
import MessagePreview from './MessagePreview/MessagePreview.vue'
import AdvancedOptions from './AdvancedOptions/AdvancedOptions.vue'
import getEnv from '@/util/env'
import UnsuscribeService from '@/services/unsuscribe.service'
import UnsubscribeEditor from '@/components/UnsubscribeEditor/UnsubscribeEditor.vue'
import FormsEditor from '@/components/FormsEditor/FormsEditor.vue'
import FormPreview from '@/components/FormPreview/FormPreview.vue'
import FormDataTable from '@/classes/Form/FormDataTable.js'
import UrlPreview from '@/components/UrlPreview/UrlPreview.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import UsersUrlsService from '@/services/users-urls.service'
import { TrackingUrl } from '@/models/TrackingUrl'
import VerifiedSmsService from '@/services/verified-sms.service'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import CampaignModalExit from '../CampaignModalExit/CampaignModalExit.vue'
import ModalValidateSend from '../ModalValidateSend/ModalValidateSend.vue'
import { SmsRegExp } from '@/classes/SmsRegExp'
import CountryService from '@/services/country.service'
import { sha256 } from '@/util/SHA256'
import Senders from '@/components/FormConfiguration/Sms/Senders.vue'
import AutoAnswer from '@/views/Pages/Campaigns/CampaignWhatsapp/AutoAnswer/AutoAnswer.vue'
import CampaignCosts from '@/models/CampaignCosts'
import ModalResumeSend from './ModalResumeSend/ModalResumeSend.vue'
import AiAssistantAutoAnswerWhatsapp from '@/components/AiAssistant/AiAssistantAutoAnswerWhatsapp.vue'
import { WhatsappTemplateCategory } from '@/classes/WhatsappTemplateCategory'
import { AutoAnswer as AutoAnswerModel } from '@/models/AutoAnswer.js'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import HeadboardType from '@/models/whatsapp-editor/HeadboardType'
import AutomaticResponseType from '@/models/whatsapp-editor/AutomaticResponseType'
import HeadboardOption from '@/models/whatsapp-editor/HeadBoardOption'
import RoutesService from '@/services/whatsapp/routes.service'
import NewAssistantEditor from '@/components/Assistants/NewAssistantEditor.vue'
import ContactSourceService from '@/services/contact-source.service'
import { CALL_TO_ACTION_TYPE } from '@/models/whatsapp-editor/enums/CallToActionType'

export default {
  name: 'CampaignWhatsapp',
  components: {
    HeaderTopDashboard,
    FirstSection,
    SecondSection,
    SendLater,
    VueTagsInput,
    ShippingMode,
    ContactSource,
    PricesByCountry,
    MessagePreview,
    AdvancedOptions,
    MeetOptions,
    BirthdayOptions,
    TestModal,
    BalanceAlert,
    CampaingHeaderTitle,
    CampaignPreview,
    LandingPreview,
    OverlayManager,
    LandingEditor,
    MultiSelect,
    ImportFile,
    ImportManual,
    ImportGroups,
    ChangeSourceDialog,
    FormsEditor,
    FormPreview,
    UnsubscribeEditor,
    UrlPreview,
    UrlsDialog,
    VideoPlayer,
    CampaignModalExit,
    ModalValidateSend,
    Senders,
    AutoAnswer,
    ModalResumeSend,
    AiAssistantAutoAnswerWhatsapp,
    NewAssistantEditor,
  },
  data: function () {
    return {
      currentWhatsapp: new CurrentWhatsapp('whatsapp', 1024, 0, 1, 0),
      showEditor: false,
      isLandingMessage: false,
      showLanding: false,
      showForm: false,
      showUrl: false,
      showUnsubscribe: false,
      formPreviewUrl: null,
      urlPreviewUrl: null,
      urlId: null,
      unsubscribePreview: null,
      landing: { id: null, name: '', preview: '' },
      landingTemplate: new Landing(null),
      formValid: false,
      saveBarProperties: new SaveBarProperties(),
      drawer: null,
      panel: [],
      modalPrices: false,
      componentsData: new WhatsappComponentsData(),
      campaign: new Campaign(new ChannelWhatsapp()),
      preview: new WhatsappPreview(),
      contactsFileUploadResult: new ContactsFileUploadResponse(),
      channelType: ChannelType.WHATSAPP_ID,
      user: null,
      urls: {
        redirectUrl: '/',
        preview: 'campaign/whatsapp/preview',
        store: 'whatsapp/store',
        send: 'whatsapp/send',
        update: 'whatsapp/update',
        updateStatus: 'whatsapp/change-status',
        paymentUrl: 'payment',
        submitUrl: '',
      },
      fields: {
        availableFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
      options: new ComponentStatusStore(false, false, 'now'),
      regex: SmsRegExp.getRegExp(),
      emptyRecipients: false,
      contactsSourceErrors: {
        show: false,
        textContent: '',
        type: 'error',
      },
      phonesWithPrefix: new PhonesWithPrefixClass(),
      test: {
        phone: '',
        phonePrefix: '',
        testContacts: [],
      },
      sendTestDialog: false,
      readyStatus: {
        ready: false,
      },
      editorModeSaving: false,
      multiSelectHeaders: {
        name: { header: this.$t('Grupo') },
        // sendings: { header: this.$t('Envíos'), help: this.$t('Número de veces que se realizaron envíos a un grupo') },
        contacts: { header: this.$t('Contactos'), align: 'center' },
      },
      nextGroupsPage: 1,
      lastGroupsPage: 10,
      groupsTotal: 0,
      selectedGroups: [],
      sourceKeys: {
        IMPORT_KEY: 'import',
        CONTACTS_KEY: 'contacts',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
      },
      changeRecipientsSource: false,
      nextRecipientsSource: null,
      state: new State('state', ['urlCampaignExit']),
      showFormEditor: false,
      showFormPreviewFlag: true,
      formDataTable: new FormDataTable(),
      showUnsubscribePreviewIframe: false,
      editUrlDialog: false,
      trackingUrl: new TrackingUrl(),
      resetUrlPreviewFlag: true,
      toggleVideoPlayer: false,
      readySecondSection: true,
      urlCampaignExit: '',
      confirmCampaignExit: false,
      routeLeave: true,
      showValidateModal: false,
      loadingValidateModal: false,
      countryIsoValidateSms: [
        'IN', 'IND',
      ],
      lastCampaignHash: null,
      isSendingTest: false,
      isSendingCampaign: false,
      disabledEditTemplateWhatsapp: false,
      configPanel: 0,
      autoAnswerPanel: [],
      whatsappSenders: [],
      showDialogCalculatedCosts: {
        show: false,
      },
      templateLanguages: [],
      categories: [
        {
          value: 1,
          text: this.$t('Utilidad'),
        },
        {
          value: 2,
          text: this.$t('Marketing'),
        },
        {
          value: 3,
          text: this.$t('Autenticación'),
        },
      ],
      aiDrawerCtrl: {
        show: false,
      },
      selectedAutoAnswerId: 'default',
    }
  },
  methods: {
    openChatbotEdit () {
      const found = this.componentsData.chatBots.filter((bot) => {
        return bot.id === this.campaign.sendings[0].channel.chatbotId
      })
      const botAssistant = found[0]
      EventBus.$emit('gpt-assistants-edit', botAssistant)
    },

    setAutoAnswer (selectedAutoAnswer) {
      this.selectedAutoAnswerId = selectedAutoAnswer
    },
    setAiIdea (idea) {
      this.campaign.sendings[0].channel.autoAnswer.find(autoAnswer => autoAnswer.id === this.selectedAutoAnswerId).message = idea
    },
    getLanguages () {
      WhatsappTemplateService.getLanguages()
        .then(response => {
          this.templateLanguages = response.data
        })
    },
    openVideoPlayer () {
      let videoWhatsapp = {}
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        videoWhatsapp = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 1)
      }
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic && this.$router.currentRoute.query.landing !== undefined) {
        videoWhatsapp = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 5)
      }
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic && this.$router.currentRoute.query.poll !== undefined) {
        videoWhatsapp = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 8)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.meet) {
        videoWhatsapp = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 2)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.birthday) {
        videoWhatsapp = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 3)
      }

      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoWhatsapp.url,
        title: videoWhatsapp.title,
      })
    },
    resetUrlPreview () {
      this.resetUrlPreviewFlag = false
      setTimeout(() => { this.resetUrlPreviewFlag = true })
    },
    prepareEditUrlDialog () {
      UsersUrlsService.getUrlById(this.urlId).then((data) => {
        this.trackingUrl.setData(data)
        this.editUrlDialog = true
      })
    },
    showOverlayUrlPreview () {
      EventBus.$emit('showOverlayUrlPreview')
    },
    showOverlayUnsubscribe () {
      EventBus.$emit('showOverlayUnsubscribe')
    },
    async showOverlayPreviewForm () {
      const user = JSON.parse(localStorage.getItem('user'))
      this.formDataTable.previewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + this.formId + '?token=' + user.token
      this.formDataTable.preview = true
      EventBus.$emit('showOverlayFormPreview')
      this.updateFormPreview()
    },
    updateFormPreview () {
      this.showFormPreviewFlag = false
      setTimeout(() => {
        this.showFormPreviewFlag = true
      })
    },
    closeFormsEditor () {
      EventBus.$emit('closeOverlayFormEditor')
    },
    showOverlayFormEditor () {
      EventBus.$emit('showOverlayFormEditor')
    },
    saveState () {
      this.state.setState(this.$data)
    },
    recoverState () {
      this.state.getState(this.$data)
    },
    saveAndExit () {
    },
    setInvalidElements (invalid) {
      this.saveBarProperties.invalid = invalid
    },
    updatePreview (data) {
      this.preview.loadData(data)
      this.landingTemplate.customVars = this.fields.availableFields
      EventBus.$emit('hideAlert')
      if (this.preview.contactsPhone.length > 0) {
        this.preview.contactPreview = this.preview.contactsPhone[0]
      }
    },
    updateContactsFileUploadResult (data) {
      this.contactsFileUploadResult = new ContactsFileUploadResponse(data)
      EventBus.$emit('hideAlert')
    },
    save () {
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED
      this.routeLeave = false
      let url = this.urls.store
      if (this.campaign.id) {
        url = `${this.urls.update}/${this.campaign.id}`
      }
      return this.persist(url, false)
    },
    send () {
      this.routeLeave = false
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        if (this.campaign.sendings[0].sendingDate) {
          this.campaign.sendings[0].statusId = SendingStatuses.PENDING
        } else {
          this.campaign.sendings[0].statusId = SendingStatuses.PREPARING
        }
        if (this.urls.submitUrl === this.urls.store) {
          this.urls.submitUrl = this.urls.send
        }
        this.isSendingCampaign = true
        return this.persist(this.urls.submitUrl, true)
      }
    },
    sendTemplateToVerify () {
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED

      const data = this.campaign.formatData()
      CampaignService.persist(data, this.urls.store).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.setEditUrl()
          this.routeLeave = false
          const data = {
            sendingId: response.sending.id,
            campaignId: response.sending.campaign_id,
            type: this.campaign.subtype,
            status: 'validating',
          }
          CampaignService.whatsappSendingChangeStatus(data).then(
            () => {
              EventBus.$emit('showAlert', 'success', this.$t('Campaña actualizada correctamente'))
              this.$router.push({ name: 'HomePage' })
            },
            () => {
              EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
            },
          ).finally(() => {
          })
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
        },
      )
    },
    acceptedValidateModal () {
      this.routeLeave = false
      this.loadingValidateModal = true
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED

      const data = this.campaign.formatData()
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.campaign.sendings[0].recipientsTest = []
          this.setEditUrl()

          const data = {
            sendingId: response.sending.id,
            campaignId: response.sending.campaign_id,
            type: this.campaign.subtype,
          }
          CampaignService.smsSendingChangeStatusValidating(data).then(
            () => {
              EventBus.$emit('showAlert', 'success', this.$t('Campaña actualizada correctamente'))
              this.$router.push({ name: 'HomePage' })
            },
            () => {
              EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
            },
          ).finally(() => {
            this.loadingValidateModal = false
            this.toggleValidateModal()
          })
        },
        (error) => {
          if (error.response.status === 422) {
            let errorsAlert = ''
            const errors = error.response.data
            for (const index in errors) {
              errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
            }
            return EventBus.$emit('showAlert', 'danger', errorsAlert)
          }
          if (error.response.status === 400) {
            return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }

          if (error.response.data.balanceAlert) {
            return this.showBalanceAlert(error.response.data.isScheduled)
          }
          this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
        },
      )
    },
    toggleValidateModal () {
      this.showValidateModal = !this.showValidateModal
    },
    persist (url, redirect = false) {
      const data = this.campaign.formatData()
      data.sendings[0].channel.template = JSON.stringify(this.campaign.sendings[0].channel.template)

      if (this.campaign.sendings[0].channel.selectedHeadboardOption === HeadboardOption.options.LOCATION) {
        data.sendings[0].channel.headboard = this.campaign.sendings[0].channel.localizationData
      }

      CampaignService.persist(data, url)
        .then(
          async (response) => {
            if (redirect) {
              this.$router.push({ path: this.urls.redirectUrl })
            } else {
              this.campaign.id = response.sending.campaign_id
              this.campaign.sendings[0].channel.id = response.sending.channel_id
              this.campaign.sendings[0].recipientsTest = []
              this.setEditUrl()

              if (this.showCosts) {
                CampaignService.getCampaignCost(this.campaign.id)
                .then(
                  (response) => {
                    this.$set(this.campaign, 'campaignCosts', new CampaignCosts())
                    this.campaign.campaignCosts.load(response)
                  },
                  () => {},
                )
                .finally(() => {
                  this.showCosts = false
                  this.showDialogCalculatedCosts.show = true
                })
              }

              if (this.goToPayment) {
                this.$router.push({
                  name: 'addPayment',
                  query: {
                    campaign_id: this.campaign.id,
                    campaign_type: 'whatsapp',
                    campaign_sub_type: this.campaign.subtype,
                  },
                })
              }
            }
            this.lastCampaignHash = await sha256(JSON.stringify(this.campaign))
            let message = this.isSendingTest
              ? this.$t('Se ha enviado la prueba correctamente')
              : this.$t('Se ha guardado su campaña correctamente')
            message = this.isSendingCampaign
              ? this.$t('Se ha creado su campaña correctamente')
              : message
            EventBus.$emit('showAlert', 'success', message)
            this.isSendingTest = false
            this.isSendingCampaign = false
          },
          (error) => {
            if (error.response.status === 422) {
              let errorsAlert = ''
              const errors = error.response.data
              for (const index in errors) {
                errorsAlert += this.$errorMessages.getValidationMessage(errors[index].error, index, errors[index].values) + '<br>'
              }
              return EventBus.$emit('showAlert', 'danger', errorsAlert)
            }
            if (error.response.status === 400) {
              return EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
            }

            if (error.response.data.balanceAlert) {
              return this.showBalanceAlert(error.response.data.isScheduled)
            }
            this.showErrorAlert(this.$t('Ocurrió un error al crear la campaña'))
          },
        )
    },
    saveAndGetCost () {
      this.showCosts = true
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      let url = this.urls.store
      if (this.campaign.id) {
        url = `${this.urls.update}/${this.campaign.id}`
      }
      return this.persist(url, false)
    },
    saveAndBalance () {
      this.goToPayment = true
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      let url = this.urls.store
      if (this.campaign.id) {
        url = `${this.urls.update}/${this.campaign.id}`
      }
      return this.persist(url, false)
    },
    showErrorAlert (errors) {
      EventBus.$emit('showAlert', 'danger', errors)
    },

    showBalanceAlert (isScheduled) {
      if (isScheduled) {
        EventBus.$emit('balanceAlertScheduled')
      } else {
        EventBus.$emit('balanceAlert')
      }
    },

    saveAndRedirectToPayment () {
      this.$router.push(this.urls.paymentUrl)
      this.campaign.costs.skipValidateBalance = true
      return this.save()
    },

    confirmScheduled () {
      this.campaign.costs.skipValidateBalance = true
      return this.send()
    },

    setFlashSms (value) {
      this.campaign.sendings[0].channel.flash = value
    },

    sendTest () {
      if (this.preview.totalRecipients === 0) {
        return
      }
      EventBus.$emit('send-test-event')
    },

    setDefaultCampaignData () {
      const date = moment().format('YYYY-MM-DD-HH:mm:ss')
      this.campaign.name = 'Whatsapp' + '-' + date
      this.campaign.sendings[0].sendingTimezone = this.componentsData.defaultTimezone
      this.setType()
    },

    setType () {
      this.campaign.type = this.campaign.subtype === CampaignType.automatic.subtypes.meet || this.campaign.subtype === CampaignType.automatic.subtypes.birthday
        ? CampaignType.automatic.id
        : CampaignType.basic.id
    },

    refreshPreviewCosts () {
      if (this.preview.totalRecipients === 0) {
        return
      }
      const contactSource = this.$refs.contactSource
      if (this.campaign.recipientsSource === this.sourceKeys.IMPORT_KEY || this.campaign.recipientsSource === this.sourceKeys.IMPORT_COPY_PASTE_KEY) {
        contactSource.reparseFile()
      } else {
        contactSource.loadPreviewData()
      }
    },

    showAllGroups (data) {
      this.componentsData.groups = data
    },

    setReady () {
      this.readyStatus.ready = false
      setTimeout(() => {
        this.readyStatus.ready = true
      })
    },

    saveAndSendTest () {
      this.isSendingTest = true
      EventBus.$emit('close-test-dialogs')
      this.campaign.sendings[0].recipientsTest = this.phonesWithPrefix.phonesWithPrefix
      return this.save(false)
    },

    setEditUrl () {
      this.urls.submitUrl = this.urls.update + '/' + this.campaign.id
    },

    showPanels () {
      if (this.campaign.sendings[0].sendingDate || this.campaign.sendings[0].expirationDate) {
        this.panel = [0]
      }
      if (
        this.campaign.sendings[0].tags.length > 0 ||
        this.campaign.sendings[0].batchMinutes ||
        this.campaign.sendings[0].channel.certified ||
        this.campaign.sendings[0].channel.flash
      ) {
        if (this.panel !== null) {
          this.panel = [0, 1]
        } else {
          this.panel = [1]
        }
      }
    },

    cancelSendLater () {
      this.panel.splice(0, 1)
    },

    setLanding (landing, landingTemplate) {
      this.isLandingMessage = true
      this.landing = landing
      this.landingTemplate = landingTemplate
      this.$refs.secondSection.addFieldToMessage('{LAND_URL}')
    },

    async updateLandingPreview () {
      this.campaign.sendings[0].channel.templateStructure = this.landingTemplate.structure
      const data = {
        structure: this.landingTemplate.structure,
        typeLanding: 'Email',
      }

      LandingTemplateService.getPreviewFromStructure(data)
        .then(
          (response) => {
            this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
          },
          () => { },
        )
    },

    getPreview () {
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.landingTemplate.structure)
      })
    },
    async loadGroups () {
      EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
      this.componentsData.loadingGroups = true
      if (this.nextGroupsPage === 1) {
        this.componentsData.groups = []
      }
      let groups = []
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        groups = await groupService.getWithContactsSendingsByChannel({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })
      } else {
        groups = await groupService.getWithContactsSendingsByChannelWithDates({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })
      }
      this.lastGroupsPage = groups.last_page
      this.groupsTotal = groups.total
      this.componentsData.groups = [...this.componentsData.groups, ...groups.data]
      this.nextGroupsPage++
      this.componentsData.groups = this.componentsData.groups.filter(ar => !this.selectedGroups.find(rm => rm.id === ar.id))
      this.componentsData.loadingGroups = false
      EventBus.$emit('showLoading', false)
    },
    async loadAllGroups () {
      EventBus.$emit('showLoading', true, this.$t('Cargando grupos...'))
      const groups = await groupService.getAllGroups({
        channelType: this.channelType,
      })
      EventBus.$emit('showLoading', false)
      this.componentsData.groups = [...groups]
    },
    onCleanSelectedGroups () {
      this.selectedGroups = []
      this.componentsData.groups = [...this.componentsData.groups]
    },
    onCheckShowDrawer (recipientsSource) {
      this.saveState()
      EventBus.$emit('contactSource:saveState')
      if (this.campaign.recipientsSource !== recipientsSource) {
        if (this.preview.totalRecipients > 0) {
          this.nextRecipientsSource = recipientsSource
          this.changeRecipientsSource = true
        } else {
          this.openDrawer(recipientsSource)
        }
      } else {
        this.openDrawer(recipientsSource)
      }
    },
    openDrawer (recipientsSource) {
      this.campaign.recipientsSource = recipientsSource
      EventBus.$emit('contactSource:showDrawer', recipientsSource)
    },
    confirmOpenDrawer () {
      const regex = /\{COL\d+\}/
      if (regex.test(this.campaign.sendings[0].channel.headboard)) {
        this.campaign.sendings[0].channel.headboard = this.campaign.sendings[0].channel.headboard.replace(/COL\d+/, 'COL+')
      }
      if (Array.isArray(this.campaign.sendings[0].channel.callToActionResponses)) {
        EventBus.$emit('variableSubstitutionDialog:reset')
        this.campaign.sendings[0].channel.callToActionResponses.forEach(response => {
          if (response.type === 1) {
            if (regex.test(response.url)) {
              response.url = response.url.replace(/COL\d+/, 'COL+')
            }
          }

          if (response.type === 3) {
            response.code = null
          }
        })
      }
      EventBus.$emit('contactSource:cleanDrawer')
      EventBus.$emit('contactSource:resetDrawer')
      this.onCleanSelectedGroups()
      this.preview = new WhatsappPreview()
      this.changeRecipientsSource = false
      this.saveState()
      this.openDrawer(this.nextRecipientsSource)
      this.clearAvailableFieldsMessage()
    },
    cancelOpenDrawer () {

    },
    clearAvailableFieldsMessage () {
      if (this.fields.currentFieldsMessage.length > 0) {
        for (const currentField of this.fields.currentFieldsMessage) {
          let message = this.campaign.sendings[0].channel.message
          const current = new RegExp('{' + currentField + '}', 'g')
          message = message.replace(current, '')
          this.campaign.sendings[0].channel.message = message
        }
      }

      this.preview.contactPreview = 0
    },
    setCurrentWhatsappEncodingByCountryIso () {
      this.readySecondSection = false
      this.$refs.secondSection.extraEncodings = []
      this.currentWhatsapp = new CurrentWhatsapp('GSM', this.currentWhatsapp.characters, this.currentWhatsapp.chunks, this.currentWhatsapp.maxCharactersInfo)
      setTimeout(() => {
        this.readySecondSection = true
      })
    },
    closeAcceptDrawer () {
      this.setCurrentWhatsappEncodingByCountryIso()
      this.saveState()
      EventBus.$emit('contactSource:saveState')
    },
    closeCancelDrawer () {
      this.recoverState()
      if (this.preview.totalRecipients === 0) {
        EventBus.$emit('contactSource:resetDrawer')
        this.onCleanSelectedGroups()
      } else {
        EventBus.$emit('contactSource:recoverState')
      }
    },
    isSelectedRecipientsSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    setSelectedGroups () {
      this.selectedGroups = [...this.componentsData.groups]
    },
    checkAndSetEventDate () {
      const programCampaignDate = localStorage.getItem('programCampaignDate')
      if (programCampaignDate && this.campaign.subtype === 'basic') {
        this.autoAnswerPanel = [2]
        this.campaign.sendings[0].sendingDate = programCampaignDate
      }
      localStorage.removeItem('programCampaignDate')
    },
    updateSelectedGroups (newGroups) {
      this.preview.loading = true
      this.selectedGroups = newGroups
      this.campaign.groups = newGroups.map(g => g.id)
      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        if (newGroups.length) {
          this.preview.totalRecipients = newGroups.reduce((prev, curr) => {
            return prev + curr.contacts
          }, 0)
        } else {
          this.preview.totalRecipients = 0
        }
      }

      ContactSourceService.loadPreview(Object.assign(this.campaign, { fullContactPreview: true }), this.urls.preview)
      .then(
        (response) => {
          this.campaign.sendings[0].totalRecipients = response.totalRecipients
          this.campaign.costs.sendingCost = response.sendingCost
          this.loadingData = false
          this.updatePreview(response)
          this.preview.loading = false
        },
        () => {},
      )
    },
    loadDefaults () {
      this.loadGroups()
      this.showSendLater = true
      this.setDefaultCampaignData()
      this.urls.submitUrl = this.urls.store
      this.checkAndSetEventDate()
      this.setReady()
    },

    async showFormPreview (formId) {
      this.formId = formId
      const user = JSON.parse(localStorage.getItem('user'))
      this.formPreviewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + formId + '?token=' + user.token
      this.showForm = true
    },
    async showFormPreviewEdit (formId) {
      this.formId = formId
      const user = JSON.parse(localStorage.getItem('user'))
      this.formPreviewUrl = await getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + formId + '?token=' + user.token
      EventBus.$emit('showOverlayFormEditor')
    },
    async showUrlPreview (urlId) {
      this.urlId = urlId
      this.urlPreviewUrl = this.componentsData.availableUrls[urlId].url_preview
      this.showUrl = true
    },
    async showUnsubscribePreview () {
      this.showUnsubscribePreviewIframe = false
      UnsuscribeService.getPage({ type: 'Whatsapp' }).then((page) => {
        UnsuscribeService.preview(page.unsubscribePage).then(
          (response) => {
            this.unsubscribePreview = response.page
            this.showUnsubscribePreviewIframe = true
          },
          () => {},
        )
      })

      this.showUnsubscribe = true
    },
    showOverlayUnsubscribeEditorPreview () {
      this.showOverlayUnsubscribe()
      setTimeout(() => {
        EventBus.$emit('showUnsubscribePreviewInEditor')
      })
    },
    setSender () {
        const verifiedBrandId = this.$route.params.verifiedBrandId
        if (!verifiedBrandId) {
            return
        }
        VerifiedSmsService.getVerifiedSenders({ verifiedBrandId })
        .then(
            (response) => {
            this.campaign.sendings[0].channel.sender = response.sender
            },
            () => {},
        )
    },
    messageValidateSelected (WhatsappValidateDlt) {
      this.campaign.sendings[0].channel.sender = WhatsappValidateDlt.sender
      this.campaign.sendings[0].channel.message = WhatsappValidateDlt.template_content
    },
    validateCampaign () {
      this.$refs.campaignValidation?.validate()
    },
    setCampaignFromTemplate (template) {
      this.campaign.name = template.name
      this.campaign.sendings[0].channel.message = template.message
      this.campaign.sendings[0].channel.selectedHeadboardType = template.head_board_type
      this.campaign.sendings[0].channel.selectedHeadboardOption = template.head_board_option
      this.campaign.sendings[0].channel.headboard = template.head_board_option !== 2 ? JSON.parse(template.head_board) : template.head_board
      this.campaign.sendings[0].channel.footerPage = template.footer_page
      this.campaign.sendings[0].channel.callActionUrlText = template.call_action_url_text
      this.campaign.sendings[0].channel.callActionUrl = template.call_action_url
      this.campaign.sendings[0].channel.callActionUrlActive = template.call_action_url_active
      this.campaign.sendings[0].channel.callActionPhoneText = template.call_action_phone_text
      this.campaign.sendings[0].channel.callActionPhone = template.call_action_phone
      this.campaign.sendings[0].channel.callActionPhoneActive = template.call_action_phone_active
      this.campaign.sendings[0].channel.callActionCountryPrefix = template.call_action_country_prefix
      this.campaign.sendings[0].channel.selectedCallToActionOption = template.call_to_action_option
      this.campaign.sendings[0].channel.automaticResponses = JSON.parse(template.automatic_responses)
      this.campaign.sendings[0].channel.callToActionResponses = JSON.parse(template.call_to_action_responses)

      if (HeadboardType.isAutomaticReponseOption(template.head_board_type)) {
        this.campaign.sendings[0].channel.automaticResponses.forEach((response, index) => {
          if (response.visible) {
            const answer = new AutoAnswerModel()
            answer.label = (parseInt(response.type) === AutomaticResponseType.responseTypes.NORMAL)
                            ? response.text
                            : response.textDisableMarketing
            // TODO: response.id does not exist!!
            answer.id = index
            this.campaign.sendings[0].channel.autoAnswer.push(answer)
          }
        })
      }
    },
  },
  computed: {
    isChatbotEnabled () {
      return this.campaign.sendings[0].channel.hasChatbot ?? false
    },
    countVarsMessage () {
      if (this.campaign.sendings[0].channel.template.template_whatsapp_category_id === WhatsappTemplateCategory.AUTHENTICATION) {
        return 0
      }
      return this.campaign.sendings[0].channel.message.split('{COL+}').length - 1
    },
    isValidHeader () {
      const headerTypes = [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ]
      const hasHeader = headerTypes.includes(this.campaign.sendings[0].channel.selectedHeadboardType)
      const header = this.campaign.sendings[0].channel.headboard
      const isLocation = this.campaign.sendings[0].channel.selectedHeadboardOption === HeadboardOption.options.LOCATION
      if (hasHeader && header !== null) {
        const hasNoVars = JSON.stringify(header).split('{COL+}').length - 1 === 0
        return isLocation || (header !== null && hasNoVars)
      }
      return true
    },
    isValidCallToAction () {
      const headerTypes = [
        HeadboardType.headboardTypes.CALL_ACTION_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ]
      const hasCallToAction = headerTypes.includes(this.campaign.sendings[0].channel.selectedHeadboardType) &&
                              this.campaign.sendings[0].channel.callToActionResponses.length > 0
      const callActionUrl = this.campaign.sendings[0].channel.callToActionResponses.filter(response => {
        return response.type === CALL_TO_ACTION_TYPE.EXTERNAL_LINK
      })
      if (hasCallToAction && callActionUrl?.length > 0) {
        return callActionUrl.reduce((previousValue, currentValue) => {
            return previousValue && currentValue?.url?.split('{COL+}').length - 1 === 0
        })
      }
      return true
    },
    canUseCustomSender () {
      return true
    },
    // eslint-disable-next-line complexity
    isValidSendTest: function () {
      return (
        this.preview.totalRecipients > 0 &&
        this.campaign.name !== '' &&
        this.isCampaingSendingHeader &&
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.tooManyShippingHours &&
        !this.saveBarProperties.invalid &&
        this.countVarsMessage === 0 &&
        this.isValidHeader &&
        this.isValidCallToAction
      )
    },
    isValidSave: function () {
      return (
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.tooManyShippingHours &&
        !this.saveBarProperties.invalid &&
        this.isValidHeader &&
        this.isValidCallToAction &&
        this.isValidAutoAnswer
      )
    },
    // eslint-disable-next-line complexity
    isValidSend: function () {
      return (
        this.preview.totalRecipients > 0 &&
        this.campaign.name !== '' &&
        this.isCampaingSendingHeader &&
        this.campaign.sendings[0].channel.sender !== '' &&
        this.campaign.sendings[0].channel.message !== '' &&
        !this.tooManyShippingHours &&
        !this.saveBarProperties.invalid &&
        this.countVarsMessage === 0 &&
        this.isValidHeader &&
        this.isValidCallToAction &&
        this.isValidAutoAnswer &&
        this.isAuthenticationCode &&
        this.isResponseCallToActionCustomField
      )
    },
    isAuthenticationCode () {
      let response = true
      if (this.campaign.sendings[0].channel.template.template_whatsapp_category_id === 3) {
        if (this.campaign.sendings[0].channel.customVerificationCodeField === null) {
          response = false
        } else {
          response = true
        }
      }
      return response
    },
    isResponseCallToActionCustomField: function () {
      return (this.campaign.sendings[0].channel.callToActionResponses.filter((response) => response.visible && response.customField === true && response.customFieldName === true).length > 0)
    },
    /* eslint-disable complexity */
    isValidAutoAnswer: function () {
      let response = true

      const hasAutoAnswer = this.campaign.sendings[0].channel.hasAutoAnswer
      if (!hasAutoAnswer) {
        return response
      }

      this.campaign.sendings[0].channel.autoAnswer.forEach((autoAnswer) => {
        if (
          autoAnswer.answer.body === '' ||
          (autoAnswer.answerType === 'interactive' &&
            autoAnswer.answer.action.buttons.length === 0 &&
            autoAnswer.answer.type === 0) ||
          (autoAnswer.answerType === 'interactive' &&
            autoAnswer.answer.action.sections.length === 0 &&
            autoAnswer.answer.type === 1) ||
          (autoAnswer.answerType === 'interactive' &&
            autoAnswer.answer.header.visible === true &&
            (
              (autoAnswer.answer.header.url === '' &&
                autoAnswer.answer.header.type !== 2) ||
              (autoAnswer.answer.header.content === '' &&
                autoAnswer.answer.header.type === 2)
            )
          )
        ) {
          response = false
        }
      })

      return response
    },
    isCampaingSendingHeader: function () {
      const headerTypes = [
        HeadboardType.headboardTypes.HEADER_TEXT,
        HeadboardType.headboardTypes.CALL_ACTION_HEADER_TEXT,
        HeadboardType.headboardTypes.RESPONSE_HEADER_TEXT,
        HeadboardType.headboardTypes.OPTION_HEADER_TEXT,
      ]
      if (headerTypes.includes(this.campaign.sendings[0].channel.selectedHeadboardType)) {
        const isLocation = this.campaign.sendings[0].channel.selectedHeadboardOption === HeadboardOption.options.LOCATION
        const headerNotEmpty = this.campaign.sendings[0].channel.headboard !== ''
        if (isLocation || headerNotEmpty) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    getShippingTotalHours () {
      const minutes = this.campaign.sendings[0].batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },
  },
  created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
  },
  mounted () {
    this.getLanguages()
    EventBus.$emit('showLoading', true)
    const campaignId = this.$route.params.id
    const templateId = parseInt(this.$route.query.templateId)
    this.user = JSON.parse(localStorage.getItem('user'))
    this.campaign.sendings[0].channel_type_id = ChannelType.WHATSAPP_ID

    this.$nextTick(function () {
      const showMultimediaLanding = this.$router.currentRoute.query.landing
      if (showMultimediaLanding !== undefined) {
        EventBus.$emit('showMultimedia', 'landings', false)
      }
      const showPolls = this.$router.currentRoute.query.poll
      if (showPolls !== undefined) {
        EventBus.$emit('showMultimedia', 'formularios', false)
      }
    })


    if (campaignId) {
      CampaignService.getWhatsappEditCampaignData(campaignId, this.$router.currentRoute.query.type)
        .then(
          async (response) => {
            this.campaign.load(response.campaign)
            this.componentsData = response.componentsData
            this.setSelectedGroups()
            this.loadGroups()
            if (!campaignId || !this.campaign.countryIso) {
              this.campaign.sendings[0].csvFileConfig.setCountry(this.user.isoCountry.toUpperCase() || this.user.isoTld.toUpperCase())
            }
            this.setEditUrl()
            this.contactsFileUploadResult = new ContactsFileUploadResponse(response.contactsFileUploadResult)
            this.campaign.costs.sendingCost = this.preview.sendingCost
            this.fields.availableFields = response.availableFields
            this.showPanels()
            this.setType()
            this.whatsappSenders = response.senders
            if (this.whatsappSenders.length === 1) {
              this.campaign.sendings[0].channel.sender = this.whatsappSenders[0].msisdn
            }
            this.disabledEditTemplateWhatsapp = true
            this.preview.totalRecipients = response.campaign.sendings[0].total_recipients
            EventBus.$emit('showLoading', false)
            this.setReady()
          },
          (error) => {
            if (error.response.data.message === 'no_edit_campaign') {
              const message = this.$t('No puedes editar esta campaña')
              this.showErrorAlert(message)
              const that = this
              EventBus.$emit('showLoading', false)
              setTimeout(function () {
                that.$router.go(-1)
              }, 1000)
            }
          },
        )
    } else {
      CampaignService.getWhatsappCreateData(this.$router.currentRoute.query.type)
      .then(
        (response) => {
          this.componentsData = new WhatsappComponentsData(response.componentsData)
          this.campaign.subtype = response.campaign.subtype
          this.loadDefaults()
          this.setSender()
          this.disabledEditTemplateWhatsapp = true
          EventBus.$emit('showLoading', false)
        },
      )

      if (templateId) {
        WhatsappTemplateService.getTemplate(templateId)
          .then(
            (response) => {
              this.setCampaignFromTemplate(response.data)
              this.whatsappSenders = response.senders
              if (this.whatsappSenders.length === 1) {
                this.campaign.sendings[0].channel.sender = this.whatsappSenders[0].msisdn
              }
              this.campaign.sendings[0].channel.template = response.data
              this.campaign.sendings[0].channel.templateWhatsappId = templateId
            },
          )
      }
    }

    EventBus.$on('changedLanguage', () => {
      CountryService.getUserCountries().then(
        (response) => {
          this.componentsData.countries = response.countries
        },
      )
    })

    EventBus.$on('validate-campaign-sender', this.validateCampaign)
    EventBus.$off('hook:unmounted', this.validateCampaign)
    EventBus.$on('AutoAnswerAiAssistant:open', () => {
      this.aiDrawerCtrl.show = true
    })
  },
}
